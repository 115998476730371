
import "./styles.css";
import {
    PlusOutlined,
    LineOutlined,
    InfoCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Form, Input, notification, Row, Select, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import { Ports } from "../../services/Ports";
import { useEffect, useState } from "react";
import _ from "lodash";

export const SearchWidget = ({
    from, setFrom,
    to, setTo,
    GidisTarihi, setGidisTarihi,
    DonusTarihi, setDonusTarihi,
    donusTarihiAktif, setDonusTarihiAktif,
    YetiskinSayisi, setYetiskinSayisi,
    CocukSayisi, setCocukSayisi,
    ChildAges, setChildAges,
    BebekSayisi, setBebekSayisi,
    searchTour,
    searchParams
}) => {
    const [departurePorts, setDeparturePorts] = useState([])
    const [arrivalPorts, setArrivalPorts] = useState([])
    const [loadingDeparturePorts, setLoadingDeparturePorts] = useState(true)
    const [loadingArrivalPorts, setLoadingArrivalPorts] = useState(true)

    useEffect(() => {
        if (donusTarihiAktif) {
            setDonusTarihi(dayjs(GidisTarihi).add(1, 'day').format('YYYY-MM-DD'))
        }
    }, [GidisTarihi])

    const fetchArrivalPorts = (value) => {
        // console.log("ferctharfrivalports");
        Ports().arrivalPorts(value).then(({ data }) => {
            if (data?.statusCode == 200) {
                setFrom(value);
                if (Array.isArray(data?.data)) {
                    setArrivalPorts(data?.data)
                    setTo(searchParams?.ArrivalRegionId || data?.data[0].regionID)
                } else {
                    notification.error({ message: 'Hata', description: 'Varış limanlarını listelerken bir hata oluştu. Lütfen daha sonra tekrar deneyin!' })
                }
                setLoadingArrivalPorts(false)
            } else {
                notification.error({ message: 'Hata', description: 'Hata oluştu, lütfen daha sonra tekrar deneyin' })
            }
        }).catch((error) => {
            // console.log("erorrkhdjk3", error);
            notification.error({ message: 'Hata', description: 'Hata oluştu, lütfen daha sonra tekrar deneyin' })
        })
    }

    const handleYetiskinSayisiChange = (amount) => {
        if (amount >= 1 && amount <= 10) {
            setYetiskinSayisi(amount);
        }
    };

    // console.log("departurePorts", departurePorts);

    const handleCocukSayisiChange = (amount) => {
        if (amount >= 0 && amount <= 5) {
            setCocukSayisi(amount);
            if (amount > CocukSayisi) {
                setChildAges([...ChildAges, ...new Array(amount - CocukSayisi).fill(2)]);
            } else {
                setChildAges(ChildAges.slice(0, amount));
            }
        }
    };

    const handleChildAgeChange = (index, increment) => {
        const newChildAges = [...ChildAges];
        newChildAges[index] = Math.max(2, Math.min(11, newChildAges[index] + increment));
        setChildAges(newChildAges);
    };

    const handleBebekSayisiChange = (amount) => {
        if (amount >= 0 && amount <= 5) {
            setBebekSayisi(amount);
        }
    };

    useEffect(() => {
        setLoadingArrivalPorts(true)
        setLoadingDeparturePorts(true)
        if (!searchParams?.DepartureRegionId && !searchParams?.ArrivalRegionId) {
            Ports().departurePorts().then(({ data }) => {
                if (Array.isArray(data?.data)) {

                    const sortByName = 'ÇEŞME'
                    const sortedPorts = data?.data.sort((a, b) => {
                        if (a.name === sortByName) return -1;
                        if (b.name === sortByName) return 1;
                        return 0;
                    });

                    setDeparturePorts(sortedPorts)
                    fetchArrivalPorts(sortedPorts[0].regionID)
                } else {
                    notification.error({ message: 'Hata', description: 'Kalkış limanlarını listelerken bir hata oluştu. Lütfen daha sonra tekrar deneyin!' })
                }

                setLoadingDeparturePorts(false)
            }).catch((error) => {
                console.log("error", error);
            })
        } else {
            Ports().departurePorts().then(({ data }) => {
                if (Array.isArray(data?.data)) {
                    setDeparturePorts(data?.data)
                    fetchArrivalPorts(searchParams?.DepartureRegionId)
                } else {
                    notification.error({ message: 'Hata', description: 'Kalkış limanlarını listelerken bir hata oluştu. Lütfen daha sonra tekrar deneyin!' })
                }
                setLoadingDeparturePorts(false)
            })
        }
    }, [])

    const disableDonusTarihi = (current) => {
        return GidisTarihi && current && current.isBefore(dayjs(GidisTarihi), 'day');
    };

    const disableDepartureDate = (current) => {
        return current && current.isBefore(dayjs(), 'day');
    };

    return (
        <>
            <div className="w-100">
                <div className="form-select-ticket">
                    <Form
                        className="search-form"
                        layout={'vertical'}
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={0} md={0}>
                                <label htmlFor="from" className="formSelectLabel">Nereden</label>
                                <select
                                    style={{ height: 56 }}
                                    id="from"
                                    name="from"
                                    className="form-item form-item-first formSelectInputForMobile"
                                    value={from}
                                    onChange={async (event) => {
                                        const value = event.target.value;
                                        try {
                                            const { data } = await Ports().arrivalPorts(value);
                                            if (data?.statusCode === 200) {
                                                setFrom(value);
                                                setArrivalPorts(data?.data);
                                                setTo(data?.data[0].regionID);
                                            } else {
                                                notification.error({ message: 'Hata', description: 'Hata oluştu, lütfen daha sonra tekrar deneyin' });
                                            }
                                        } catch (error) {
                                            notification.error({ message: 'Hata', description: 'Hata oluştu, lütfen daha sonra tekrar deneyin' });
                                        }
                                    }}
                                >
                                    {
                                        !_.isEmpty(departurePorts) && Array.isArray(departurePorts) && departurePorts.map((port) => (
                                            <option key={port.regionID} value={port.regionID}>
                                                {port.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col xs={0} sm={12} md={4}>
                                <Form.Item label='Nereden' className="search-widget-form-item">
                                    <Select
                                        loading={!departurePorts || loadingDeparturePorts}
                                        style={{ height: 56 }}
                                        id="from"
                                        name="from"
                                        className="form-item form-item-first"
                                        value={from}
                                        onChange={async (value) => {
                                            Ports().arrivalPorts(value).then(({ data }) => {
                                                if (data?.statusCode == 200) {
                                                    setFrom(value);
                                                    setArrivalPorts(data?.data)
                                                    setTo(data?.data[0].regionID)
                                                } else {
                                                    notification.error({ message: 'Hata', description: 'Hata oluştu, lütfen daha sonra tekrar deneyin' })
                                                }
                                            }).catch((error) => notification.error({ message: 'Hata', description: 'Hata oluştu, lütfen daha sonra tekrar deneyin' }))
                                        }}
                                    >
                                        {
                                            !_.isEmpty(departurePorts) && Array.isArray(departurePorts) && departurePorts?.map((port) => (
                                                <Select.Option key={port.regionID} value={port.regionID}>
                                                    {port.name}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={0} md={0}>
                                <label htmlFor="to" className="formSelectLabel">Nereye</label>
                                <select
                                    className="select-way form-item formSelectInputForMobile"
                                    style={{ height: 56 }}
                                    id="to"
                                    name="to"
                                    value={to}
                                    onChange={(e) => setTo(e.target.value)}
                                >
                                    {
                                        !_.isEmpty(arrivalPorts) && Array.isArray(arrivalPorts) && arrivalPorts.map((port) => (
                                            <option key={port.regionID} value={port.regionID}>
                                                {port.name}
                                            </option>
                                        ))
                                    }
                                </select>

                            </Col>
                            <Col xs={0} sm={12} md={4}>
                                <Form.Item label='Nereye' className="search-widget-form-item">
                                    <Select
                                        loading={loadingArrivalPorts}
                                        className="select-way form-item"
                                        style={{ height: 56 }}
                                        id="to"
                                        name="to"
                                        value={to}
                                        onChange={(value) => setTo(value)}
                                    >
                                        {
                                            !_.isEmpty(arrivalPorts) && Array.isArray(arrivalPorts) && arrivalPorts?.map((port) => (
                                                <Select.Option key={port.regionID} value={port.regionID}>
                                                    {port.name}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={4}>
                                <Form.Item label="Gidiş Tarihi" className='search-widget-form-item'>
                                    <DatePicker
                                        onChange={(date) => setGidisTarihi(dayjs(date).format('YYYY-MM-DD'))}
                                        label='Gidiş tarihi'
                                        id="GidisTarihi"
                                        name="GidisTarihi"
                                        value={dayjs(GidisTarihi)}
                                        format='YYYY-MM-DD'
                                        style={{ height: 56 }}
                                        className={`form-item departure-date ${donusTarihiAktif ? "aktif" : ""}`}
                                        disabledDate={disableDepartureDate}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={4}>
                                <Form.Item
                                    label='Dönüş Tarihi'
                                    // style={{ marginRight: 10 }}
                                    className='search-widget-form-item'
                                >
                                    <DatePicker
                                        // onChange={(date) => setDonusTarihi(dayjs(date).format('YYYY-MM-DD'))}
                                        onChange={(date) => {
                                            console.log("date23", date);
                                            if (date) {
                                                setDonusTarihiAktif(true)
                                                setDonusTarihi(dayjs(date).format('YYYY-MM-DD'))
                                            } else {
                                                console.log("falsewe");
                                                setDonusTarihiAktif(false)
                                                setDonusTarihi("")
                                            }
                                        }}
                                        // disabled={!donusTarihiAktif}
                                        label='Dönüş tarihi'
                                        id="DonusTarihi"
                                        name="DonusTarihi"
                                        value={DonusTarihi && dayjs(DonusTarihi).isValid() ? dayjs(DonusTarihi) : ""}
                                        format='YYYY-MM-DD'
                                        style={{ height: 56 }}
                                        className={`form-item return-date ${donusTarihiAktif ? "aktif" : "pasif"}`}
                                        disabledDate={disableDonusTarihi}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={4}>
                                <Form.Item className='search-widget-form-item' label='Yolcular'>
                                    <Select
                                        className="form-item"
                                        placeholder="Yolcuları seçin"
                                        style={{ height: 56 }}
                                        value={`${YetiskinSayisi + CocukSayisi + BebekSayisi} yolcu seçildi`}
                                        popupClassName="custom-dropdown"
                                        dropdownRender={(menu) => (
                                            <div style={{ padding: 12 }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '50%' }}>
                                                        <span>Yetişkin </span>
                                                        <Tooltip title='12 yaşından büyük yolcularımız yetişkin statüsünde yolculuk ederler.'>
                                                            <span><InfoCircleOutlined /></span>
                                                        </Tooltip>
                                                        <br />
                                                        <span style={{ fontSize: 12, color: 'gray' }}>12+</span>
                                                    </div>
                                                    <Space style={{ width: '50%', justifyContent: 'flex-end', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                                        <Button disabled={YetiskinSayisi == 0} type="primary" onClick={() => handleYetiskinSayisiChange(Math.max(0, YetiskinSayisi - 1))} icon={<LineOutlined />} />
                                                        <Input style={{ height: 32, width: 50, textAlign: 'center' }} value={YetiskinSayisi} onChange={(e) => handleYetiskinSayisiChange(Number(e.target.value))} readOnly />
                                                        <Button disabled={YetiskinSayisi == 10} type="primary" onClick={() => handleYetiskinSayisiChange(YetiskinSayisi + 1)} icon={<PlusOutlined />} />
                                                    </Space>
                                                </div>

                                                <Divider dashed style={{ marginTop: 16, marginBottom: 8 }} />

                                                <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '50%' }}>
                                                        <span>Çocuk </span>
                                                        <Tooltip title='Son uçuş tarihinde 12. yaş gününü kutlamamış yolcularımız çocuk yolcu statüsünde seyahat edebilirler. Çocuk yolcularımız 7. yaş gününden itibaren ebeveynlerinin onayıyla tek başlarına seyahat edebilirler.'>
                                                            <span><InfoCircleOutlined /></span>
                                                        </Tooltip>
                                                        <br />
                                                        <span style={{ fontSize: 12, color: 'gray' }}>2 - 11</span>
                                                    </div>

                                                    <Space style={{ width: '50%', justifyContent: 'flex-end', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                                        <Button disabled={CocukSayisi == 0} type="primary" onClick={() => handleCocukSayisiChange(Math.max(0, CocukSayisi - 1))} icon={<LineOutlined />} />
                                                        <Input style={{ height: 32, width: 50, textAlign: 'center' }} value={CocukSayisi} onChange={(e) => handleCocukSayisiChange(Number(e.target.value))} readOnly />
                                                        <Button disabled={CocukSayisi == 5} type="primary" onClick={() => handleCocukSayisiChange(CocukSayisi + 1)} icon={<PlusOutlined />} />
                                                    </Space>
                                                </div>

                                                {CocukSayisi > 0 && (
                                                    <div style={{ marginTop: 16 }}>
                                                        {Array.from({ length: CocukSayisi }).map((_, index) => (
                                                            <div key={index} style={{ marginBottom: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <span>{index + 1}. Çocuk Yaşı: </span>
                                                                <Space style={{ marginLeft: 8 }}>
                                                                    <Button type="default" onClick={() => handleChildAgeChange(index, -1)} icon={<LineOutlined />} />
                                                                    <Input style={{ height: 32, width: 50, textAlign: 'center' }} value={ChildAges[index]} readOnly />
                                                                    <Button type="default" onClick={() => handleChildAgeChange(index, 1)} icon={<PlusOutlined />} />
                                                                </Space>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                <Divider dashed style={{ marginTop: 16, marginBottom: 8 }} />

                                                <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '50%' }}>
                                                        <span>Bebek</span><br />
                                                        <span style={{ fontSize: 12, color: 'gray' }}>2 yaş altı</span>
                                                    </div>

                                                    <Space style={{ width: '50%', justifyContent: 'flex-end', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                                        <Button disabled={BebekSayisi == 0} type="primary" onClick={() => handleBebekSayisiChange(Math.max(0, BebekSayisi - 1))} icon={<LineOutlined />} />
                                                        <Input style={{ height: 32, width: 50, textAlign: 'center' }} value={BebekSayisi} onChange={(e) => handleBebekSayisiChange(Number(e.target.value))} readOnly />
                                                        <Button disabled={BebekSayisi == 5} type="primary" onClick={() => handleBebekSayisiChange(BebekSayisi + 1)} icon={<PlusOutlined />} />
                                                    </Space>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={4}>
                                <Form.Item label='' style={{ paddingTop: 30.5 }} className='search-widget-form-item'>
                                    <Button
                                        type='primary'
                                        className="ticket-search-btn"
                                        style={{
                                            textDecoration: "none",
                                            borderRadius: "5px",
                                        }}
                                        onClick={searchTour}
                                        icon={<SearchOutlined />}
                                    >
                                        Bileti Ara
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
};