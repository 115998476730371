import { useState, useEffect } from "react";
import { Ports } from "../../services/Ports";
import { useNavigate } from 'react-router-dom';
import { SearchWidget } from "../SearchWidget";
import dayjs from "dayjs";

const BiletAl = () => {
  const navigate = useNavigate()

  const [from, setFrom] = useState("Yükleniyor..");
  const [to, setTo] = useState("Yükleniyor..");
  const [GidisTarihi, setGidisTarihi] = useState("");
  const [DonusTarihi, setDonusTarihi] = useState("");
  const [donusTarihiAktif, setDonusTarihiAktif] = useState(true);
  const [YetiskinSayisi, setYetiskinSayisi] = useState(1);
  const [CocukSayisi, setCocukSayisi] = useState(0);
  const [BebekSayisi, setBebekSayisi] = useState(0);
  const [portList, setPortList] = useState([])
  const [ChildAges, setChildAges] = useState([]);

  useEffect(() => {
    // Günün tarihini al
    const bugun = new Date().toISOString().split("T")[0];
    setGidisTarihi(bugun);
    if (donusTarihiAktif) {
      setDonusTarihi(bugun);
    } else {
      setDonusTarihi("");
    }
  }, [donusTarihiAktif]);

  useEffect(() => {
    Ports().departurePorts().then(({ data }) => {
      if (data?.statusCode == 200) {
        const ports = data?.data

        const sortByName = 'ÇEŞME'
        const sortedPorts = ports.sort((a, b) => {
          if (a.name === sortByName) return -1;
          if (b.name === sortByName) return 1;
          return 0;
        });

        setPortList(sortedPorts)
      }
    }).catch(error => console.log(error))
  }, [])

  const searchTour = () => {
    const searchParams = {
      RouteType: 2,
      ServiceDate: GidisTarihi,
      ReturnServiceDate: dayjs(DonusTarihi).format('YYYY-MM-DD'),
      DepartureRegionId: from,
      ArrivalRegionId: to,
      AdultQuantity: YetiskinSayisi,
      ChildQuantity: CocukSayisi,
      ChildAges,
      InfantQuantity: BebekSayisi,
      VehicleQuantity: 0,
      HavePets: false,
      DepartureExpeditionId: 0,
      ReturnExpeditionId: 0,
      LangCode: "TR",
      donusTarihiAktif
    }
    navigate('/bilet-al', {
      state: searchParams
    });
  }

  return (
    <SearchWidget
      from={from}
      setFrom={setFrom}
      to={to}
      setTo={setTo}
      GidisTarihi={GidisTarihi}
      setGidisTarihi={setGidisTarihi}
      DonusTarihi={DonusTarihi}
      setDonusTarihi={setDonusTarihi}
      donusTarihiAktif={donusTarihiAktif}
      setDonusTarihiAktif={setDonusTarihiAktif}
      YetiskinSayisi={YetiskinSayisi}
      setYetiskinSayisi={setYetiskinSayisi}
      CocukSayisi={CocukSayisi}
      setCocukSayisi={setCocukSayisi}
      ChildAges={ChildAges}
      setChildAges={setChildAges}
      BebekSayisi={BebekSayisi}
      setBebekSayisi={setBebekSayisi}
      searchTour={searchTour}
    />
  );
};

export default BiletAl;
